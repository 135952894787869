module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-webmention/gatsby-browser.js'),
      options: {"plugins":[],"username":"rpsychologist.com","identity":{"twitter":"krstoffr"},"mentions":true,"pingbacks":true,"domain":"rpsychologist.com","token":"l3SXWtYCVwhBSSNDF9D8bw"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/home/kris/rpsychologist-blog/node_modules/gatsby-remark-images","id":"580fd6f0-a0f4-5cb7-ac28-2bea65acb525","name":"gatsby-remark-images","version":"3.3.34","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/home/kris/rpsychologist-blog/node_modules/gatsby-remark-images-zoom","id":"9efe111e-a794-5c7e-9bc1-f0c5c2906aa6","name":"gatsby-remark-images-zoom","version":"1.1.0","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onClientEntry","onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/home/kris/rpsychologist-blog/node_modules/gatsby-plugin-use-dark-mode","id":"6e3f4e1f-2039-5903-8757-ab18dd031862","name":"gatsby-plugin-use-dark-mode","version":"1.1.2","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":["onRenderBody"]}],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":960,"linkImagesToOriginal":false,"showCaptions":["title"],"markdownCaptions":false}},"gatsby-remark-images-zoom",{"resolve":"gatsby-remark-autolink-headers","options":{"offsetY":104}},{"resolve":"gatsby-remark-katex","options":{"strict":"ignore","output":"html"}},{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 1.0725rem"}},{"resolve":"gatsby-remark-copy-linked-files"},{"resolve":"gatsby-remark-smartypants"}]},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-remark-images-zoom/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":104},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-47065595-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-rpsych/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"R Psychologist - Kristoffer Magnusson","short_name":"R Psychologist","start_url":"/","background_color":"#ffffff","theme_color":"#3498DB","display":"minimal-ui","icon":"assets/rpsych-favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"dc4201899d3f4be9ba1c72783e73faef"},
    }]
