// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---gatsby-theme-rpsych-cohend-src-pages-cohend-js": () => import("./../../../../gatsby-theme-rpsych-cohend/src/pages/cohend.js" /* webpackChunkName: "component---gatsby-theme-rpsych-cohend-src-pages-cohend-js" */),
  "component---gatsby-theme-rpsych-correlation-src-pages-correlation-js": () => import("./../../../../gatsby-theme-rpsych-correlation/src/pages/correlation.js" /* webpackChunkName: "component---gatsby-theme-rpsych-correlation-src-pages-correlation-js" */),
  "component---gatsby-theme-rpsych-likelihood-src-pages-likelihood-js": () => import("./../../../../gatsby-theme-rpsych-likelihood/src/pages/likelihood.js" /* webpackChunkName: "component---gatsby-theme-rpsych-likelihood-src-pages-likelihood-js" */),
  "component---gatsby-theme-rpsych-pvalue-src-pages-pvalue-js": () => import("./../../../../gatsby-theme-rpsych-pvalue/src/pages/pvalue.js" /* webpackChunkName: "component---gatsby-theme-rpsych-pvalue-src-pages-pvalue-js" */),
  "component---gatsby-theme-rpsych-src-pages-404-js": () => import("./../../../../gatsby-theme-rpsych/src/pages/404.js" /* webpackChunkName: "component---gatsby-theme-rpsych-src-pages-404-js" */),
  "component---gatsby-theme-rpsych-therapist-effects-src-pages-therapist-effects-js": () => import("./../../../../gatsby-theme-rpsych-therapist-effects/src/pages/therapist-effects.js" /* webpackChunkName: "component---gatsby-theme-rpsych-therapist-effects-src-pages-therapist-effects-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-posts-js": () => import("./../../../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-viz-js": () => import("./../../../src/pages/viz.js" /* webpackChunkName: "component---src-pages-viz-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

